export const ROLE = [
  { value: 0, label: '超级管理员' },
  { value: 1, label: '管理员' },
  { value: 2, label: '客户' }
]

// 支付状态
export const PAY_TYPE = [
  { value: 0, label: '未提交' },
  { value: 1, label: '待付款' },
  { value: 2, label: '已付款' },
  { value: 3, label: '已退款' }
]
