// const SERVICE_DOMAIN = '//localhost:5000/'
// const SERVICE_DOMAIN = '//admin-api.skui-expo.com/'

let SERVICE_DOMAIN = ''
if (process.env.NODE_ENV === 'production') {
  SERVICE_DOMAIN = '//admin-api.skui-expo.com/'
} else {
  SERVICE_DOMAIN = '//localhost:5500/'
}

// const IMAGE_DOMAIN = 'https://static.skui-expo.com/'
const IMAGE_DOMAIN = ''

var API = {
  IMAGE_DOMAIN: IMAGE_DOMAIN,
  SERVICE_DOMAIN: SERVICE_DOMAIN,
  LOGIN: `${SERVICE_DOMAIN}api/Login`,
  LOGIN_LOG: `${SERVICE_DOMAIN}api/SysLoginLogs`,
  USER: `${SERVICE_DOMAIN}api/SysUsers`,
  PERSONAL: {
    URL: `${SERVICE_DOMAIN}api/Personal`,
    PASSWORD: `${SERVICE_DOMAIN}api/Personal/Password`,
    ACTIVITY: {
      URL: `${SERVICE_DOMAIN}api/SkhzPersonalActivities`,
      BATCH_DELETE: `${SERVICE_DOMAIN}api/SkhzPersonalActivities/Batch/IsDeleted`,
      EXCEL: `${SERVICE_DOMAIN}api/SkhzPersonalActivities/Excel`,
      PROJECT: `${SERVICE_DOMAIN}api/SkhzPersonalActivities/{id}/Projects`,
      PROJECT_BATCH_DELETE: `${SERVICE_DOMAIN}api/SkhzPersonalActivities/{id}/Projects/Batch/IsDeleted`
    },
    PROJECT: {
      EXCEL: `${SERVICE_DOMAIN}api/SkhzPersonalProjects/Excel`,
      USER: `${SERVICE_DOMAIN}api/SkhzPersonalProjects/{id}/Users`,
      USER_EXCEL: `${SERVICE_DOMAIN}api/SkhzPersonalProjects/{id}/Users/Excel`,
      ORDER: `${SERVICE_DOMAIN}api/SkhzPersonalProjects/{id}/Orders`,
      ORDER_EXCEL: `${SERVICE_DOMAIN}api/SkhzPersonalProjects/{id}/Orders/Excel`,
      TICKET: `${SERVICE_DOMAIN}api/SkhzPersonalProjects/{id}/Tickets`,
      TICKET_EXCEL: `${SERVICE_DOMAIN}api/SkhzPersonalProjects/{id}/Tickets/Excel`,
      IMAGE: `${SERVICE_DOMAIN}api/SkhzPersonalProjects/{id}/Images`,
      IMAGE_DELETE: `${SERVICE_DOMAIN}api/SkhzPersonalProjects/{id}/Images/Batch/IsDeleted`,
      IMAGE_INDEX: `${SERVICE_DOMAIN}api/SkhzPersonalProjects/{id}/Images/{imageId}/Index`,
      QUESTION_SETTING: `${SERVICE_DOMAIN}api/SkhzPersonalProjects/{id}/QuestionSettings`
    },
    USER: `${SERVICE_DOMAIN}api/SkhzPersonalUsers`,
    USER_EXCEL: `${SERVICE_DOMAIN}api/SkhzPersonalUsers/Excel`,
    ORDER: `${SERVICE_DOMAIN}api/SkhzPersonalOrders`,
    ORDER_EXCEL: `${SERVICE_DOMAIN}api/SkhzPersonalOrders/Excel`
  },
  ALI_OSS_UPLOAD: {
    URL: `${SERVICE_DOMAIN}api/Upload/AliOss/Images`
  },
  ACTIVITY: {
    URL: `${SERVICE_DOMAIN}api/SkhzActivities`,
    BATCH_DELETE: `${SERVICE_DOMAIN}api/SkhzActivities/Batch/IsDeleted`,
    EXCEL: `${SERVICE_DOMAIN}api/SkhzActivities/Excel`,
    PROJECT: `${SERVICE_DOMAIN}api/SkhzActivities/{id}/Projects`,
    PROJECT_BATCH_DELETE: `${SERVICE_DOMAIN}api/SkhzActivities/{id}/Projects/Batch/IsDeleted`,
    GATA_LOGS_EXCEL: `${SERVICE_DOMAIN}api/SkhzActivities/{id}/GataLogs/Excel`
  },
  PROJECT: {
    EXCEL: `${SERVICE_DOMAIN}api/SkhzProjects/Excel`,
    USER: `${SERVICE_DOMAIN}api/SkhzProjects/{id}/Users`,
    USER_EXCEL: `${SERVICE_DOMAIN}api/SkhzProjects/{id}/Users/Excel`,
    ORDER: `${SERVICE_DOMAIN}api/SkhzProjects/{id}/Orders`,
    ORDER_EXCEL: `${SERVICE_DOMAIN}api/SkhzProjects/{id}/Orders/Excel`,
    TICKET: `${SERVICE_DOMAIN}api/SkhzProjects/{id}/Tickets`,
    TICKET_EXCEL: `${SERVICE_DOMAIN}api/SkhzProjects/{id}/Tickets/Excel`,
    IMAGE: `${SERVICE_DOMAIN}api/SkhzProjects/{id}/Images`,
    IMAGE_DELETE: `${SERVICE_DOMAIN}api/SkhzProjects/{id}/Images/Batch/IsDeleted`,
    IMAGE_INDEX: `${SERVICE_DOMAIN}api/SkhzProjects/{id}/Images/{imageId}/Index`,
    QUESTION_SETTING: `${SERVICE_DOMAIN}api/SkhzProjects/{id}/QuestionSettings`
  },
  ORGANIZER: {
    URL: `${SERVICE_DOMAIN}api/SkhzOrganizers`,
    EXCEL: `${SERVICE_DOMAIN}api/SkhzOrganizers/Excel`,
    SELECTION: `${SERVICE_DOMAIN}api/SkhzOrganizers/Detailed/Selections`,
    BATCH_DELETE: `${SERVICE_DOMAIN}api/SkhzOrganizers/Batch/IsDeleted`
  },
  REGISTRATION_USER: {
    URL: `${SERVICE_DOMAIN}api/SkhzUsers`,
    EXCEL: `${SERVICE_DOMAIN}api/SkhzUsers/Excel`
  },
  ORDER: {
    URL: `${SERVICE_DOMAIN}api/SkhzOrders`,
    EXCEL: `${SERVICE_DOMAIN}api/SkhzOrders/Excel`
  }
}

export default API
