<template>
  <div :class="['oa-form', { 'disabled': disabled }]">
    <el-card content-position="left" class="form-header">
      <el-page-header @back="goBack" content="系统账号" size="small"></el-page-header>
      <div class="button-box">
        <span v-if="disabled">
          <el-button round type="primary" size="small" @click="disabled = false">
            <font-awesome-icon fas icon="edit"></font-awesome-icon>&nbsp;编辑
          </el-button>
        </span>
        <span v-else>
          <el-button round type="primary" size="small" @click="submit">
            <font-awesome-icon fas icon="save"></font-awesome-icon>&nbsp;保存
          </el-button>
          <el-button round type="warning" size="small" @click="disabled = true">
            <font-awesome-icon fas icon="reply"></font-awesome-icon>&nbsp;取消
          </el-button>
        </span>
      </div>
    </el-card>
    <div class="form-content">
      <el-form status-icon :disabled="disabled" :model="entity" :rules="validationRules" ref="form" label-width="120px" size="small">
        <el-card content-position="left">
          <div slot="header">
            <span class="card-header-label">基本信息</span>
          </div>
        <el-form-item prop="Name" label="名称">
          <el-input v-model="entity.Name" size="mini" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="主办方" prop="OrganizerId">
          <el-select filterable remote reserve-keyword v-model="entity.OrganizerId" :remote-method="getOrganizers"
            @change="selectOrganizer" class="template-select" size="small" placeholder="请输入名称搜索主办方">
            <el-option :value="$store.state.guid" label="未选择"></el-option>
            <el-option v-for="item in organizers" :key="item.Id" :value="item.Id" :label="item.Name"
              style="width: 450px;">
              <label>{{item.Name}}</label><label v-if="item.StartDate" style="float:right;">
                {{new Date(item.StartDate).toString('yyyy-MM-dd')}}&nbsp;至&nbsp;
                {{new Date(item.EndDate).toString('yyyy-MM-dd')}}
              </label>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="UserName" label="账号">
          <el-input v-model="entity.UserName" size="mini" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item prop="Password" label="密码">
          <el-input type="password" v-model="entity.Password" size="mini" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item prop="RePassword" label="重复密码">
          <el-input type="password" v-model="entity.RePassword" size="mini" placeholder="请输入重复密码"></el-input>
        </el-form-item>
        <el-form-item prop="Role" label="角色">
          <el-radio v-model="entity.Role" :label="roles[0].value" size="mini">
            <font-awesome-icon fas icon="users" class="text-danger"></font-awesome-icon>&nbsp;{{roles[0].label}}
          </el-radio>
          <el-radio v-model="entity.Role" :label="roles[1].value" size="mini">
            <font-awesome-icon fas icon="user" class="text-primary"></font-awesome-icon>&nbsp;{{roles[1].label}}
          </el-radio>
          <el-radio v-model="entity.Role" :label="roles[2].value" size="mini">
            <font-awesome-icon fas icon="user-tie" class="text-success"></font-awesome-icon>&nbsp;{{roles[2].label}}
          </el-radio>
        </el-form-item>
        </el-card>
      </el-form>
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5'
import API from '../../apis/ticket-api'
import { USER, USER_FORM } from '../../router/base-router'
import { ROLE } from '../../assets/js/const'

export default {
  name: USER_FORM.name,
  data () {
    return {
      roles: ROLE,
      organizers: [], // 项目列表
      organizerSearchOption: { key: '' },
      organizersLoading: false, // 分销协议加载中
      disabled: true, // 允许编辑
      loading: false, // 加载中
      isAdd: false, // 添加模式
      entity: {} // 实体
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.init())
  },
  computed: {
    validationRules () {
      const validatePassword = (rule, value, callback) => {
        if (value !== this.entity.Password) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      const rule = {
        Name: [{ required: true, message: '请输入账号名称', trigger: 'blur' }],
        UserName: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        Password: [{ min: 6, message: '长度必须大于等于6', trigger: 'blur' }],
        RePassword: [{ min: 6, message: '长度必须大于等于6', trigger: 'blur' }, { validator: validatePassword, trigger: 'blur' }],
        Role: [{ required: true, message: '请选择角色权限', trigger: 'blur' }]
      }
      if (this.isAdd) {
        rule.Password.push({ required: true, message: '请输入密码', trigger: 'blur' })
        rule.RePassword.push({ required: true, message: '请输入重复密码', trigger: 'blur' })
      }
      return rule
    }
  },
  methods: {
    init () {
      if (!this.loading) {
        this.loading = true
        this.isAdd = this.$route.params.isAdd
        this.entity = {
          Password: '',
          RePassword: '',
          ...this.$route.params
        }
        if (this.isAdd) {
          this.disabled = false
        } else {
          this.getOrganizer()
        }
        this.loading = false
        this.$nextTick(() => { this.$refs.form.clearValidate() })
      }
    },
    getOrganizer () {
      if (!this.entity.OrganizerId || this.entity.OrganizerId === this.$store.state.guid) return
      this.axios.get(`${API.ORGANIZER.URL}/${this.entity.OrganizerId}`).then(response => {
        if (response) {
          this.organizers = [response]
        } else {
          this.organizers = [{ Id: response.OrganizerId, Name: '已被删除' }]
        }
      })
    },
    getOrganizers () {
      this.organizersLoading = true
      this.axios.get(API.ORGANIZER.SELECTION, {
        params: this.organizerSearchOption
      }).then(response => {
        this.organizers = response
        this.organizersLoading = false
      })
    },
    selectOrganizer (id) {
      const organizer = this.organizers.find(w => w.Id === id)
      if (organizer) {
        this.entity.OrganizerId = organizer.Id
      }
    },
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.add()
          } else {
            this.update()
          }
        }
      })
    },
    add () {
      this.axios.post(API.USER, {
        ...this.entity,
        Password: md5(this.entity.Password),
        RePassword: md5(this.entity.RePassword)
      }).then(response => {
        if (response.Status) {
          this.$router.push(USER)
        }
      })
    },
    update () {
      this.axios.put(API.USER, {
        ...this.entity,
        Password: md5(this.entity.Password),
        RePassword: md5(this.entity.RePassword)
      }).then(response => {
        if (response.Status) {
          this.$router.push(USER)
        }
      })
    },
    goBack () {
      this.$router.push(USER)
    }
  },
  created () {
    this.init()
  }
}
</script>
